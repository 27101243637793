import React from "react";
import { Form, Modal, Radio } from "antd";
import dynamic from "next/dynamic";
import {
  PORTFOLIO_BUDGET,
  PORTFOLIO_TIMEREQUERIED,
} from "@/src/const/nonPrimitive";
import { LoadingOutlined } from "@ant-design/icons";
const UserProfileImage = dynamic(
  () =>
    import(
      "@/src/components/CommonComponents/userProfileImage/userProfileImage"
    )
);
const InputComponent = dynamic(
  () => import("@/src/components/CommonComponents/Input/InputComponent")
);
const ButtonComponent = dynamic(
  () => import("@/src/components/CommonComponents/Button/ButtonComponent")
);
const TextAreaComponent = dynamic(
  () => import("@/src/components/CommonComponents/TextArea/TextAreaComponent")
);

const GetInTouchModelView = (props) => {
  const {
    isSendRequestLoading,
    form,
    freeEmailServiceError,
    userPortfolioProfileData,
    isGetInTouchOpen,
    getInTouchModalError,
    handleOpenGetInTouchModal,
    handleGetInTouchFocus,
    handleSendRequest,
    handleGetInTouchChange,
  } = props;
  const payload = form?.getFieldValue();
  return (
    <React.Fragment>
      <ButtonComponent
        btnText={"Get in touch"}
        classNames="get-in-touch"
        handleClick={handleOpenGetInTouchModal}
      />
      {isGetInTouchOpen && (
        <Modal
          open={isGetInTouchOpen}
          onCancel={handleOpenGetInTouchModal}
          footer={false}
          closeIcon={false}
          className={"get-in-touch"}
          width={486}
        >
          <div className="create-collection-main">
            <div className="profile-main">
              <UserProfileImage
                profileImage={userPortfolioProfileData?.profileData?.profileImg}
                firstName={userPortfolioProfileData?.profileData?.firstName}
                lastName={userPortfolioProfileData?.profileData?.lastName}
                imgHeight={53}
                imgWidth={53}
              />
              <p className={"get-Portfolio-title"}>
                Lets have a quick questionnaire
              </p>
            </div>
            <Form onFinish={handleSendRequest} form={form}>
              <div className={"get-details-wrap"}>
                <div className="request-input-main">
                  <p className={"designing-question"}>
                    What are you looking to design?
                  </p>
                  <Form.Item>
                    <InputComponent
                      type={"text"}
                      handleChange={(e) => handleGetInTouchChange(e)}
                      handleBlur={() =>
                        handleGetInTouchFocus("designRequirement", 80)
                      }
                      maxLength={80}
                      name={"designRequirement"}
                      showCount={true}
                      placeholder={"e.g. landing page , ios icons"}
                      error={getInTouchModalError?.designRequirement}
                      classNames={"designing-question-input"}
                    />
                  </Form.Item>
                </div>
                <div className="request-main">
                  <label className={"designing-question"}>
                    {"Your necessity period?"}
                  </label>
                  <Form.Item>
                    <Radio.Group
                      name="timeRequired"
                      defaultValue={1}
                      className="request-selected"
                    >
                      {PORTFOLIO_TIMEREQUERIED?.map((ele) => (
                        <Radio
                          value={ele?.value}
                          key={ele?.value}
                          onChange={handleGetInTouchChange}
                          className={`${payload?.timeRequired?.includes(ele?.value) ? "radio-active" : ""}`}
                        >
                          {ele?.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <p className="request-error">
                    {getInTouchModalError?.timeRequired}
                  </p>
                </div>
                <div className="request-main">
                  <h2 className={"designing-question"}>Financial estimate</h2>
                  <Form.Item>
                    <Radio.Group
                      name="budget"
                      defaultValue={1}
                      className="request-selected"
                    >
                      {PORTFOLIO_BUDGET?.map((ele) => (
                        <Radio
                          value={ele?.value}
                          key={ele?.value}
                          onChange={handleGetInTouchChange}
                          className={`${payload?.budget?.includes(ele?.value) ? "radio-active" : ""}`}
                        >
                          {ele?.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <p className="request-error">
                    {getInTouchModalError?.budget}
                  </p>
                </div>
                <div className="text-area">
                  <label className={"designing-question"}>
                    {"Additional Description"}
                  </label>
                  <Form.Item>
                    <TextAreaComponent
                      type={"text"}
                      handleChange={(e) => handleGetInTouchChange(e)}
                      handleBlur={() =>
                        handleGetInTouchFocus("projectDescription", 25000)
                      }
                      maxLength={2500}
                      name={"projectDescription"}
                      showCount={true}
                      placeholder={"Enter about project"}
                      error={getInTouchModalError?.projectDescription}
                      classNames={"designing-question-textarea"}
                    />
                  </Form.Item>
                </div>
                <p className="send-request">{freeEmailServiceError}</p>
                <div className={"get-touch-btn-wrap"}>
                  <Form.Item>
                    <ButtonComponent
                      btnText={
                        isSendRequestLoading ? (
                          <LoadingOutlined />
                        ) : (
                          "Send Request"
                        )
                      }
                      htmlType="submit"
                      disabled={isSendRequestLoading && true}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default GetInTouchModelView;
